import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const CodeTerminal = () => {
  return (
    <aside className="bg-dark text-white p-3 rounded w-100 terminal" style={{ fontFamily: 'monospace' }}>
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col xs="auto" className="d-flex gap-2">
            <div className="bg-danger" style={{ width: '12px', height: '12px', borderRadius: '50%' }} />
            <div className="bg-warning" style={{ width: '12px', height: '12px', borderRadius: '50%' }} />
            <div className="bg-success" style={{ width: '12px', height: '12px', borderRadius: '50%' }} />
          </Col>
          <Col xs="auto">
            <p className="text-muted mb-0" style={{ fontSize: '0.875rem' }}>bash</p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="text-success mb-0">$ joe-dev initiate-project</p>
            <p className="mb-0">+ personalised consultation... ready</p>
            <p className="mb-0">+ web hosting package... configured</p>
            <p className="mb-0">+ crafting digital tools... done</p>
            <p className="text-success mb-0">$ joe-dev deploy-website</p>
          </Col>
        </Row>
      </Container>
    </aside>
  );
};
