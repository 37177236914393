const WordPressServices = [
    {
    name: 'WordPress Website Design',
    description: 'Custom design services to build a website that drives results and impresses your audience.',
    features: [
      'Custom WordPress Design',
      'SEO-friendly web pages',
      'Modern stylish design',
    ],
    price: 'Starting at £650',
  },
  {
    name: 'Website In A Day',
    description: '"EMERGENCY! I NEED A WEBSITE TODAY"',
    features: [
      '24-hour turnaround for basic sites',
      'E-commerce setup (additional time required for product addition)',
      'SEO plugin and contact form setup',
    ],
    price: 'Starting at £1,500',
  },
  {
    name: 'Large Website Projects',
    description: 'Handling large and complex website projects with bespoke solutions.',
    features: [
      'Custom database driven designs',
      'Membership sites, recruitment websites, and more',
      'Personalized web solutions',
    ],
    price: 'Get in touch for a quote',
  },
  {
    name: 'Consultation and Strategy',
    description: 'Leverage my expertise to plan and strategize the optimal path for your WordPress project.',
    features: [
      'Site architecture planning',
      'Plugin and theme recommendations',
      'Performance optimization strategy',
    ],
    price: '£50 per hour',
  }
  // ... add more services as needed
];
const WordPressHostingPricing = [
  {
    name: 'TRINITY',
    description: 'Perfect for startups and small websites looking for a reliable space online.',
    features: [
      'Secure hosting',
      'Generous storage',
      'Reliable performance',
      'Single website hosting',
    ],
    price: '£25.00 per month',
  },
  {
    name: 'MORPHEUS',
    description: 'Tailored for growing businesses seeking speed, security, and great support.',
    features: [
      'Secure hosting',
      'Extra storage space',
      'Enhanced performance',
      'Single website hosting',
      'Round-the-clock support',
      'Weekly backups'
    ],
    price: '£45.00 per month',
  },
  {
    name: 'NEO',
    description: 'The ultimate choice for businesses needing top-notch performance with insights to stay ahead.',
    features: [
      'Secure hosting',
      'Guaranteed 99.9% Uptime',
      'Massive storage capacity',
      'Peak performance',
      'Premium support any time',
      'Your own unique IP address',
      'Daily backups'
    ],
    price: '£90.00 per month',
  }
];

const PricingSection = () => {
  return (
    <div id="pricing-section" className="pricing-section">
       
      
     {/* WordPress Services Section */}
      <div id="wordpress-development">
        <h1 className="centered-text">Expert WordPress Services</h1>
        <p className="centered-text">
          In the dynamic digital landscape of today, a website is not just a setup-and-forget affair — it is a living entity that grows and evolves with your business.<br /><br />
          Leverage my expertise in WordPress to steer clear of technical hassles, leaving you with the time and peace of mind to focus on what you do best — nurturing your business.<br></br>
          I am here to provide you with a seamless, reliable, and stress-free web experience that aligns perfectly with your business objectives.
        </p>
          <div className="services-container wordpress-services-container">
            {WordPressServices.map(service => (
              <div key={service.name} className="service-card glow-on-hover">
                <h2 className="service-title">{service.name}</h2>
                <p className="service-description">{service.description}</p>
                <ul className="feature-list">
                  {service.features.map(feature => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
                <p className="service-price">{service.price}</p>
              </div>
            ))}
          </div>
       </div>

      {/* WordPress Hosting Pricing Section */}
      <div id="wordpress-hosting">
        <h1 className="centered-text">WordPress Hosting Pricing</h1>
        <p className="centered-text">
          In a world where your digital footprint is your business’s front door, a stellar online presence isn't a luxury—it's essential.<br /><br />
          Stepping up to professional hosting means saying goodbye to technical hassles and hello to a website that works hard for your business, day in and day out.<br /><br />
          Entrust your web worries to me, liberating you to steer your business to soaring heights, undistracted by the nuts and bolts of website management.
              </p>
          <div className="services-container hosting-container">
            {WordPressHostingPricing.map(service => (
              <div key={service.name} className="service-card glow-on-hover">
                <h2 className="service-title">{service.name}</h2>
                <p className="service-description">{service.description}</p>
                <ul className="feature-list">
                  {service.features.map(feature => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
                <p className="service-price">{service.price}</p>
              </div>
            ))}
          </div>
      
        </div>
    </div>
  );
};

export default PricingSection;
