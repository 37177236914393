import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/fullstacklogo.webp';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const handleToggle = () => {
    setToggled((prevToggled) => !prevToggled);
  };

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="xl" className={`${scrolled ? "scrolled" : ""} ${toggled ? "toggled" : ""}`} aria-label="Main Navigation">
          <Container>
              <Navbar.Brand href="#">
                  <img id="logo" src={logo} alt="FullStackJoe Logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} aria-label="Toggle Navigation">
                  <span className="navbar-toggler-icon"></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                      <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')} aria-label="Home Link">Home</Nav.Link>
                      {/* <Nav.Link href="#icons-section" className={activeLink === 'iconssection' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('icons-section')} aria-label="Technologies Link">Technologies</Nav.Link> */}
                      <Nav.Link href="#wordpress-development" className={activeLink === 'wordpressdevelopment' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('wordpress-development')} aria-label="Wordpress Development">Wordpress Development</Nav.Link>
                      <Nav.Link href="#wordpress-hosting" className={activeLink === 'wordpresshosting' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('wordpress-hosting')} aria-label="Wordpress Hosting">Wordpress Hosting</Nav.Link>
                      <Nav.Link href="#portfolio-section" className={activeLink === 'portfoliosection' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('portfolio-section')} aria-label="Portfolio Link">Portfolio</Nav.Link>
                      <Nav.Link href="#connect" className={activeLink === 'connect' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('connect')} aria-label="Contact Link">Contact</Nav.Link>
                  </Nav>
               
              </Navbar.Collapse>
          </Container>
      </Navbar>
    </Router>
  )
}
